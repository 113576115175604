import {editCart} from "@/api";

export function editCartInfo() {
    const userInfo = JSON.parse(localStorage.getItem('userinfo'))
    const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
  if (userInfo == null || orderInfo == null) {
    return
  }
    const params = {
        "customerName": userInfo.customerName||'',
        "customerAddress": userInfo.address||'',
        "customerPhone": userInfo.phone||'',
        "customerEmail": userInfo.email||'',
        "customerMemo": '',
        "orderLine": orderInfo.map(item => (
            {
                "productSKU": item.itemNo,
                "productQTY": item.qty+''
            }
        ))
    };
    // 调用接口修改购物车信息
    editCart(params).then(res => {
        console.log(res);
    }).catch(err => {
        console.log(err);
    })
}
