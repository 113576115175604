/*与后台交互模块 */
import ajax from './ajax'

const baseUrl = '/api'

/*** 提交订单 */
export const placeOrder = (data) => ajax(`${baseUrl}/v1/order/place-order`, data, 'POST')

/*** 修改购物车信息 */
export const editCart = (data) => ajax(`${baseUrl}/v1/order/cart`, data, 'POST')
